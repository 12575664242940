import { HttpClient } from './HttpClient';
import { GetTagModel } from './models/TagModel';

export interface TagsApi {
    add(name: string): Promise<GetTagModel>;
    delete(id: string): Promise<void>;
    getAll(): Promise<GetTagModel[]>;
    update(id: string, name: string): Promise<GetTagModel>;
}

export const createTagsApi = (client: HttpClient): TagsApi => {
    return {
        add: async(name: string) => 
            await client.post('/api/tags', {
                name
            }),
        delete: async(id: string) =>
            await client.delete(`/api/tags/${id}`),
        getAll: async () => 
            await client.get<GetTagModel[]>('api/tags'),
        update: async (id: string, name: string) => 
            await client.put(`/api/tags/${id}`, {
                name
            })
    }
}
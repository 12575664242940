import { useContext } from "react";
import { GetNoteModel } from "../services/models/NoteModel";
import { ServicesContext } from "../services/ServicesContext";

export function useUpdateNote(){
    const services = useContext(ServicesContext)
    
    return {
        update: async (note: GetNoteModel) => {
            await services.notesApi.update(note.id, note);
        }
    }
}
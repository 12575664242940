import { GetNoteModel, NoteModel } from "../services/models/NoteModel";
import {ViewNote} from "./ViewNote";
import './NotesList.scss';
import { Modal } from "antd";
import { EditNote } from "./EditNote";
import { useEffect, useState } from "react";
import { useUpdateNote } from "../hooks/UseUpdateNote";
import { useDeleteNote } from "../hooks/UseDeleteNote";

export interface notesListProps {
    notes: GetNoteModel[]
    triggerRefresh: () => Promise<void>
}

export function NotesList({notes, triggerRefresh}: notesListProps) {
    const [selectedNote, setSelectedNote] = useState<NoteModel | GetNoteModel>();
    
    const updateNote = useUpdateNote();   
    const deleteNote = useDeleteNote();
  
    useEffect(() => {
        if (! (selectedNote)) document.body.removeAttribute('style'); // fix missing scrollbar after model is closed
    }, [selectedNote])    
      
    const handleOnSaveEditNote = async (note: NoteModel) => {
        note.content = note.content;
        note.tags = note.tags;

        await updateNote.update(note as GetNoteModel);
        await triggerRefresh();

        setSelectedNote(undefined);    
    }

    const handleOnCancelEdit = () => {
        setSelectedNote(undefined);
    };

    const handleSetNoteInEditMode = (note: NoteModel) => {
        setSelectedNote(note);
    }
    
    const handleDeleteNote = async(note: GetNoteModel) => {
        await deleteNote.delete(note.id);
        await triggerRefresh();
    }

    return <>
        {notes && notes.map(c =>
            <ViewNote 
                key={c.id} 
                note={c} 
                onEdit={handleSetNoteInEditMode}
                onDelete={handleDeleteNote} />
        )}

        {(selectedNote) && 
            <Modal open={true} width={1000} footer={<></>} onCancel={handleOnCancelEdit} title={"Notitie wijzigen"}>
                <EditNote note={selectedNote} onCancel={handleOnCancelEdit} onSave={handleOnSaveEditNote} />
            </Modal>
        }
    </>
}
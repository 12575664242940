import { useState, useEffect, useContext } from 'react';
import {GetNoteModel} from "../services/models/NoteModel";
import { ServicesContext } from '../services/ServicesContext';
import { GetTagModel } from '../services/models/TagModel';

export interface UseGetNotes {    
    notes: GetNoteModel[]
    isLoading: boolean
    refresh: () => Promise<void>
}

export function useGetNotes(searchText: string, selectedTags: GetTagModel[]): UseGetNotes {
    const [notes, setNotes] = useState<GetNoteModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const services = useContext(ServicesContext)

    const refresh = async () => {
        setIsLoading(true);

        const notes = await services.notesApi.search(searchText, selectedTags);

        setIsLoading(false);
        setNotes(notes);        
    }

    useEffect(() => {
        refresh();
    }, [searchText, selectedTags]);
    
    return {        
        notes,
        isLoading,
        refresh
    }
}
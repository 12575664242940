import { NotesList } from "./components/NotesList";
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { SelectTagsList } from "./components/SelectTagsList";
import { useGetNotes } from "./hooks/UseGetNotes";
import { GetTagModel } from "./services/models/TagModel";
import { GetNoteModel, NoteModel } from "./services/models/NoteModel";
import { Button, Input, Modal } from "antd";
import { UserContext, loggedOutUser } from "./app";
import { EditNote } from "./components/EditNote";
import { useAddNote } from "./hooks/UseAddNote";
import { AddTag } from "./components/AddTag";
import { useAddTag } from "./hooks/UseAddTag";
import { useGetTags } from "./hooks/UseGetTags";

export function AppContainer() {
  const [searchText, setSearchText] = useState('');
  const [selectedTags, setSelectedTags] = useState<GetTagModel[]>([]);
  const [selectedNote, setSelectedNote] = useState<NoteModel | GetNoteModel>();

  const addNote = useAddNote();
  const addTag = useAddTag();

  const {tags, refresh: refreshTags} = useGetTags();

  const user = useContext(UserContext);

  useEffect(() => {
    if (! (selectedNote)) document.body.removeAttribute('style'); // fix missing scrollbar after model is closed
  }, [selectedNote])

  const { notes, refresh: refreshNotes } = useGetNotes(searchText, selectedTags);

  const handleOnSearchTextChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const delayDebounceFn = setTimeout(() => setSearchText(e.target.value), 1000);
    return () => clearTimeout(delayDebounceFn);
  };

  const handleOnLogoutClick = () => {
    user.userChanged(loggedOutUser);
  }

  const handleSetNoteInAddMode = () => {
    setSelectedNote({
      content: '',
      tags: []
    });
  }

  const handleOnSaveAddNote = async (note: NoteModel) => {
    await addNote.add(note);
    await refreshNotes();

    setSelectedNote(undefined);
}

  const handleOnCancelEditNote = () => {
    setSelectedNote(undefined);
  }

  const handleOnSaveTag = async (name: string) => {
    await addTag.add(name);
    await refreshTags();
  }

  const handleRefresh = async () => {
    await refreshNotes();
  }

  return (<>
    <div className="App">
      <div className="container-wrapper">
        <div className="menu">
          <h1>#My_Notes</h1>
          <div>
            <Input type="text" placeholder="Zoeken..." onChange={handleOnSearchTextChanged} />
          </div>
          <div>
            <SelectTagsList tags={tags} onSelectionChanged={(tags) => setSelectedTags(tags)} />            
          </div>
          <div>
            <AddTag onSave={handleOnSaveTag} />
          </div>
        </div>

        <div className="container">
          <div className="actionbuttons">
            <div>
              <Input onFocus={handleSetNoteInAddMode} placeholder="Notitie toevoegen" />
            </div>
            <div>
              <Button onClick={handleOnLogoutClick}>Uitloggen</Button>
            </div>
          </div>

          <NotesList notes={notes} triggerRefresh={handleRefresh} />
        </div>
      </div>
    </div>

    {(selectedNote) && 
      <Modal open={true} width={1000} footer={<></>} onCancel={handleOnCancelEditNote} title={"Notitie toevoegen"}>
        <EditNote note={selectedNote} onCancel={handleOnCancelEditNote} onSave={handleOnSaveAddNote} />
      </Modal>
    }
  </>);
}
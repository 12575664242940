import { useContext } from "react";
import { ServicesContext } from "../services/ServicesContext";
import { GetTagModel } from "../services/models/TagModel";

export function useAddTag(){
    const services = useContext(ServicesContext)
    
    return {
        add: async (name: string): Promise<GetTagModel> => {
            return await services.tagsApi.add(name);
        }
    }
}
import { useState } from 'react';
import { GetNoteModel, NoteModel } from '../services/models/NoteModel';
import MDEditor from '@uiw/react-md-editor';
import { Button, Select, SelectProps } from 'antd';
import { useGetTags } from '../hooks/UseGetTags';
import { DefaultOptionType } from 'antd/es/select';

export interface EditNoteProps {
    note: NoteModel;
    onSave: (note: NoteModel) => void;
    onCancel: () => void;
}

export function EditNote({note, onSave, onCancel}: EditNoteProps) {
    const [noteContent, setNoteContent] = useState(note.content);
    const [noteTags, setNoteTags] = useState(note.tags);

    const {tags} = useGetTags();

    const allTagOptions: DefaultOptionType[] = tags.map(c => ({
        label: c.name,
        value: c.id        
    }));

    const handleCancel = () => {
        onCancel();
    }

    const handleSaveNote = () => {
        note.content = noteContent;
        note.tags = noteTags;

        onSave(note);
    }

    const handleTagChange = (value: any[]) => {
        setNoteTags(tags.filter(t => value.find(v => v === t.id)));        
    }

    const availableTags = allTagOptions.filter(t => ! noteTags.find(c => c.id === t.value));
    const selectedTags = allTagOptions.filter(t => ! availableTags.find(s => s.value == t.value));

    return <>
        <div className="note-edit">            
            <div className='content' data-color-mode="light">                
                <MDEditor
                    id='editor'
                    value={noteContent}
                    height={800}
                    preview="edit"
                    onChange={(value) => setNoteContent(value!)} />             
            </div>
            <div className='tags'>
                <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Select tags"
                    onChange={handleTagChange}
                    value={selectedTags}
                    options={availableTags} />
            </div>
            <div className='footer-buttons'>
                <Button onClick={handleCancel}>Annuleren</Button>
                <Button type='primary' onClick={handleSaveNote}>Opslaan</Button>
            </div>
        </div>
    </>;
}

import { AxiosResponse } from 'axios';

export class AxiosResponseDateInterceptor {
    ISODateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

    isIsoDateString(value: unknown): value is string {
        return typeof value === "string" && this.ISODateFormat.test(value);
    }

    handleDateProperties(data: unknown) {
        if (data === null || data === undefined || typeof data !== "object") return data;

        for (const [key, val] of Object.entries(data)) {
            // @ts-expect-error this is a hack to make the type checker happy
            if (this.isIsoDateString(val)) data[key] = new Date(val);
            else if (typeof val === "object") this.handleDateProperties(val);
        }

        return data;
    }

    static getInstance(): (response: AxiosResponse<any, any>) => AxiosResponse<any, any> {
        var interceptor = new AxiosResponseDateInterceptor();

        return (response) => {
            interceptor.handleDateProperties(response.data);
            return response;
        };
    }
}

import { GetNoteModel } from '../services/models/NoteModel'
import { format } from "date-fns";
import MDEditor from '@uiw/react-md-editor';
import './ViewNote.scss';
import { Button, Card, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

export interface ViewNoteProps {
    note: GetNoteModel
    onEdit: (note: GetNoteModel) => void
    onDelete: (note: GetNoteModel) => void
}

export function ViewNote({note, onEdit, onDelete}: ViewNoteProps) {
    const weekDays = 'Zondag Maandag Dinsdag Woensdag Donderdag Vrijdag Zaterdag'.split(' ');

    const title = <div className='actionbuttons'>
        <div><small>{weekDays[note.created.getDay()] + ' ' + format(note.created, "dd-MM-yyyy")}</small></div>
        <div>
            <Button icon={<EditOutlined />} type="text" onClick={() => onEdit(note)} />
            <Popconfirm 
                title="Verwijder notitie"
                description="Weet je zeker dat je deze notitie wilt verwijderen?"
                onConfirm={() => onDelete(note)}
                okText="Ja"
                cancelText="Nee">
                <Button icon={<DeleteOutlined />} type="text" />
            </Popconfirm>
        </div>
    </div>


    return <>
        <Card title={title} className='note'>
        <div>
            <div className='content' data-color-mode="light">
                <MDEditor.Markdown source={note.content} />                   
            </div>
            <div className='tags'>
                {note.tags.map(t => <span key={t.id}>{t.name}</span>)}
            </div>
        </div>
        </Card>
    </>;
}
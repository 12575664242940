import { useContext } from "react";
import { ServicesContext } from "../services/ServicesContext";
import { LoggedInUserModel } from "../services/models/TagModel";


export interface UseLogin {
    login: (userName: string, password: string) => Promise<LoggedInUserModel>
}

export function useLogin(): UseLogin {
    const services = useContext(ServicesContext)

    const login = async (userName: string, password: string): Promise<LoggedInUserModel> => {
       return await services.accountApi.login(userName, password);
    }
    
    return {
        login
    }
}
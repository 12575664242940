import { createContext, useContext, useEffect, useState } from 'react';
import { AxiosAdapter } from './services/adapters/AxiosAdapter';
import { Services, ServicesContext, createServices } from "./services/ServicesContext";
import "./App.scss";
import { AppContainer } from "./AppContainer";
import { Login } from './components/Login';

interface User {
  isAuthenticated: boolean
  accessToken: string
  userName: string
}

export const loggedOutUser: User = {
  accessToken: '',
  userName: '',
  isAuthenticated: false
}

interface UserContext {
  user: User,
  userChanged: (user: User) => void
}

export const UserContext = createContext<UserContext>({
  user: loggedOutUser,
  userChanged: () => {}
});  

export function App() {    
  const [apiServices, setApiServices] = useState<Services>()
  const [servicesInitialized, setServicesInitialized] = useState<boolean>()

  const handleUserUpdated = (user: User) => {
    if (user.isAuthenticated) {
      localStorage.setItem('token', JSON.stringify(user));
    } else {
      localStorage.removeItem('token');
    }

    setServicesInitialized(false); // services need to be recreated
    setUser(user);
  }

  const getUser = () => {
    var token = localStorage.getItem('token');
    if (token != null) {
      return JSON.parse(token) as User;
    }

    return loggedOutUser;
  }

  const [user, setUser] = useState<User>(getUser());

  useEffect(() => {
    const uri = process.env.NODE_ENV === 'production' ? 'https://notes.ruijterkamp.nl/': 'http://localhost:5149'; // todo: to settings file
    const client = new AxiosAdapter(uri, user.accessToken);  
    setApiServices(createServices(client));
    setServicesInitialized(true);
  }, [user]);
  
  if (! servicesInitialized) return <></>

  return <>
    <ServicesContext.Provider value={apiServices!}>
      <UserContext.Provider value={{ user: user, userChanged: handleUserUpdated }}>
        {user.isAuthenticated && <AppContainer /> }
        {!user.isAuthenticated && <Login />}
      </UserContext.Provider>
    </ServicesContext.Provider>
  </>
}
import { useState, useEffect, useContext } from 'react';
import { ServicesContext } from '../services/ServicesContext';
import { GetTagModel } from '../services/models/TagModel';

export interface UseGetNotes {
    tags: GetTagModel[]
    isLoading: boolean    
    refresh: () => Promise<void>
}

export function useGetTags(): UseGetNotes {
    const [tags, setTags] = useState<GetTagModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const services = useContext(ServicesContext)

    const loadTags = async () => {
        setIsLoading(true);

        const notes = await services.tagsApi.getAll()

        setTags(notes);
        setIsLoading(false);
    }

    useEffect(() => {
        loadTags();              
    }, []);
    
    return {
        tags,
        isLoading,
        refresh: loadTags
    }
}
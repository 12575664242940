import { useContext } from "react";
import { ServicesContext } from "../services/ServicesContext";
import { GetNoteModel, NoteModel } from "../services/models/NoteModel";

export function useAddNote(){
    const services = useContext(ServicesContext)
    
    return {
        add: async (note: NoteModel): Promise<GetNoteModel> => {
            return await services.notesApi.add(note);
        }
    }
}
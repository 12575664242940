import { FormEvent, useContext, useState } from "react";
import { useLogin } from "../hooks/UseLogin";
import { UserContext } from "../app";
import { Alert, Button, Checkbox, Input } from "antd";
import "./Login.scss";

export function Login() {
    const {login} = useLogin();

    const userContext = useContext(UserContext);

    const [error, setError] = useState('');
    const [userName, setUserName] = useState<string>("");
    const [password, setPassword] = useState<string>("");    

    const handleLogin = async(e: FormEvent<HTMLFormElement>) => {
        try { 
            e.preventDefault();

            setError('');

            var user = await login(userName, password);

            userContext.userChanged({
                accessToken: user.token,
                isAuthenticated: true,
                userName            
            });
        } catch {            
            setError('Inloggen is mislukt');
        }
    }

    return <>
        <form onSubmit={handleLogin} autoComplete="on" action="#">
            <div className="login">
                <div className="header">Inloggen</div>
                <div><Input type="text" name="username" value={userName} onChange={(e) => setUserName(e.target.value)} placeholder="Gebruikersnaam" required /></div>
                <div><Input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Wachtwoord" required /></div>
                <div><Checkbox>Onthoud mij</Checkbox></div>
                <div><Button type="primary" htmlType="submit">Login</Button></div>
                {error && <div><Alert message={error} type="error" /></div>}
            </div>
        </form>
    </>
}

import { useContext } from "react";
import { ServicesContext } from "../services/ServicesContext";

export function useDeleteNote(){
    const services = useContext(ServicesContext)
    
    return {
        delete: async (id: string): Promise<void> => {
            await services.notesApi.delete(id);
        }
    }
}
import { Input } from "antd";
import { ChangeEvent, KeyboardEvent, useState } from "react";

export interface IAddTag {
    onSave: (name:string) => Promise<void>
}

export function AddTag({onSave: addTag}:IAddTag) {

    const [tagName, setTagName] = useState('');

    const handleOnChange = async(e: ChangeEvent<HTMLInputElement>) => {
        setTagName(e.target.value);
    }

    const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            addTag(tagName);
            setTagName('');
        }
    }

    return <div>
        <Input type="text" placeholder="Tag toevoegen" value={tagName} onKeyDown={handleOnKeyDown} onChange={handleOnChange} />
    </div>
}
import { useState } from "react";
import { GetTagModel } from "../services/models/TagModel";
import './TagsList.scss';
import { Button } from "antd";

export interface TagsListProps {
    tags: GetTagModel[]
    onSelectionChanged: (tags: GetTagModel[]) => void
}

export function SelectTagsList(props: TagsListProps) {
    const [selectedTags, setSelectedTags] = useState<GetTagModel[]>([]);

    const handleOnClick = (tag: GetTagModel) => {        
        let newList = selectedTags.filter(s => s != tag);
        if (newList.length == selectedTags.length) {
            newList.push(tag);
        }

        setSelectedTags(newList);

        props.onSelectionChanged?.(newList);
    }

    return <>
        <div className="tags">
            {props.tags.map(tag => {
                let isSelected = selectedTags.find(t => t.id == tag.id);
                return <Button key={tag.id} type={isSelected ? "primary": "dashed"}  onClick={() => { handleOnClick(tag) }}>{tag.name}</Button>
            })}
        </div>
    </>
}
import { GetNoteModel, NoteModel } from './models/NoteModel';
import { HttpClient } from './HttpClient';
import { GetTagModel, LoggedInUserModel as TokenModel } from './models/TagModel';

export interface NotesApi {
    add(note: NoteModel): Promise<GetNoteModel>
    delete(id: string): Promise<void>
    search(searchText: string, tags: GetTagModel[]): Promise<GetNoteModel[]>
    update(id: string, note: NoteModel) : Promise<GetNoteModel>        
}

export const createNotesApi = (client: HttpClient) : NotesApi => {    
    return {
        add: async(note: NoteModel) => 
            await client.post('/api/notes', {
                content: note.content,
                tags: note.tags.map(t => t.id)
            }),
        delete: async(id: string) =>
            await client.delete(`/api/note/${id}`),
        search: async (searchText: string, tags: GetTagModel[]) => 
            await client.post('api/notes/search', {
                searchText,
                tags: tags.map(t => t.id)
            }),
        update: async (id: string, note: NoteModel) => 
            await client.put(`/api/notes/${id}`, {
                content: note.content,
                tags: note.tags.map(t => t.id)
            })
    }
}

export interface AccountApi {
    login(userName: string, password: string): Promise<TokenModel>
}

export const createAccountApi = (client: HttpClient) : AccountApi => {
    return {
        login: async(userName: string, password: string): Promise<TokenModel> => {
            return await client.post('api/connect/token', {
                userName,
                password
            });
        }
    }
}
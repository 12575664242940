import axios, { AxiosInstance } from 'axios';
import { HttpClient } from '../HttpClient';
import { AxiosResponseDateInterceptor } from './AxiosResponseDateInterceptor';

export class AxiosAdapter implements HttpClient {
    _instance: AxiosInstance;

    constructor(baseURL: string, accessToken?: string) {
        this._instance = axios.create({baseURL, headers: {
            Authorization: `bearer ${accessToken}`
        }});
        this._instance.interceptors.response.use(AxiosResponseDateInterceptor.getInstance());
    }

    async get<R>(url: string): Promise<R> {
        return (await this._instance.get(url)).data;
    }
    async delete(url: string): Promise<void> {
        await this._instance.delete(url);
    }
    async post<R, D>(url: string, data?: D | undefined): Promise<R> {
        return (await this._instance.post(url, data)).data;
    }
    async put<R, D>(url: string, data?: D | undefined): Promise<R> {
        return (await this._instance.put(url, data)).data;
    }
}

import { createContext } from "react";
import { AccountApi, NotesApi, createAccountApi, createNotesApi } from "./NotesApi";
import { AxiosAdapter } from './adapters/AxiosAdapter';
import { HttpClient } from './HttpClient';
import { TagsApi, createTagsApi } from "./TagsApi";

export interface Services {
    notesApi: NotesApi
    tagsApi: TagsApi,
    accountApi: AccountApi
}
  
export const createServices = (client: HttpClient): Services => {
    return {
        notesApi: createNotesApi(client),
        tagsApi: createTagsApi(client),
        accountApi: createAccountApi(client)
    }
}
  
export const ServicesContext = createContext(createServices(new AxiosAdapter('')));